import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Automaker } from '../_interfaces/automaker';
import { Battery, BatteryPrice, Installment } from '../_interfaces/battery';
import { CityDetails } from '../_interfaces/city-details';
import { FacebookShoppingProduct } from '../_interfaces/facebook-shopping-product';
import { GoogleMerchantsProduct } from '../_interfaces/google-merchants-product';
import { Order } from '../_interfaces/order';
import { SearchableVehicle } from '../_interfaces/searchable-vehicle';
import { Testimonial } from '../_interfaces/testimonial';
import { TopCity } from '../_interfaces/top-city';
import { TopVehicle } from '../_interfaces/top-vehicle';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  /* ------------------------------------------------------------------------ */
  /* Private ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Arrays ----------------------------------------------------------------- */
  private testimonialsBS = new BehaviorSubject<Testimonial[]>([] as Testimonial[]);
  private topCitiesBS = new BehaviorSubject<TopCity[]>([] as TopCity[]);
  private topVehiclesBS = new BehaviorSubject<TopVehicle[]>([] as TopVehicle[]);

  /* Objects ---------------------------------------------------------------- */
  private originalPricesBS = new BehaviorSubject<BatteryPrice>(null);
  private discountedPricesBS = new BehaviorSubject<BatteryPrice>(null);
  private installmentSelectedBS = new BehaviorSubject<Installment>(null);
  private facebookShoppingProductBS = new BehaviorSubject<FacebookShoppingProduct>({} as FacebookShoppingProduct);
  private googleMerchantsProductBS = new BehaviorSubject<GoogleMerchantsProduct>({} as GoogleMerchantsProduct);
  private selectedAutomakerBS = new BehaviorSubject<Automaker>({} as Automaker);
  private selectedBatteryBS = new BehaviorSubject<Battery>({} as Battery);
  private selectedCityBS = new BehaviorSubject<CityDetails>({} as CityDetails);
  private selectedOrderBS = new BehaviorSubject<Order>({} as Order);
  private selectedVehicleBS = new BehaviorSubject<SearchableVehicle>({} as SearchableVehicle);

  /* Booleans --------------------------------------------------------------- */
  private batteryUselessBS = new BehaviorSubject<boolean>(false);
  private hasValidCouponAppliedBS = new BehaviorSubject<boolean>(false);
  private hasValidCouponBS = new BehaviorSubject<boolean>(false);
  private priceTermsBS = new BehaviorSubject<boolean>(true);
  private selectedCityIsOpenBS = new BehaviorSubject<boolean>(false);
  private showPhoneNumberBS = new BehaviorSubject<boolean>(true);
  private showWhatsAppButtonBS = new BehaviorSubject<boolean>(false);
  private userFromFacebookShoppingBS = new BehaviorSubject<boolean>(false);
  private userFromGoogleShoppingBS = new BehaviorSubject<boolean>(false);
  private userFromHeavyVehicleBS = new BehaviorSubject<boolean>(false);
  private userFromPartnershipBS = new BehaviorSubject<boolean>(false);
  private wantsToRescheduleBS = new BehaviorSubject<boolean>(false);

  /* Numbers ---------------------------------------------------------------- */
  private selectedCityLatitudeBS = new BehaviorSubject<number>(0);
  private selectedCityLongitudeBS = new BehaviorSubject<number>(0);

  /* Strings ---------------------------------------------------------------- */
  private batteryModelBS = new BehaviorSubject<string>('');
  private leadSourceBS = new BehaviorSubject<string>('organico');
  private leadSourceDetailsBS = new BehaviorSubject<string>('organico_formulario');
  private pageTitleBS = new BehaviorSubject<string>('Moura Ya');
  private paymentTypeBS = new BehaviorSubject<string>('');
  private phoneNumberBS = new BehaviorSubject<string>('08003459951');
  private userOriginBS = new BehaviorSubject<string>('Moura Ya');
  private utmBlogWidgetPostSlugBS = new BehaviorSubject<string>('');
  private utmCampaignBS = new BehaviorSubject<string>('');
  private utmMediumBS = new BehaviorSubject<string>('');
  private utmSourceBS = new BehaviorSubject<string>('');
  private utmTesteABBS = new BehaviorSubject<string>('');
  private utmTipoSearchBS = new BehaviorSubject<string>('');
  private whatsAppWelcomeBS = new BehaviorSubject<string>(encodeURI('Hola, necesito una batería'));

  /* ------------------------------------------------------------------------ */
  /* Public ----------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Arrays ----------------------------------------------------------------- */
  public testimonials$ = this.testimonialsBS.asObservable();
  public topCities$ = this.topCitiesBS.asObservable();
  public topVehicles$ = this.topVehiclesBS.asObservable();

  /* Objects ---------------------------------------------------------------- */
  public originalPricesBS$ = this.originalPricesBS.asObservable();
  public discountedPricesBS$ = this.discountedPricesBS.asObservable();
  public installmentSelected$ = this.installmentSelectedBS.asObservable();
  public facebookShoppingProduct$ = this.facebookShoppingProductBS.asObservable();
  public googleMerchantsProduct$ = this.googleMerchantsProductBS.asObservable();
  public selectedAutomaker$ = this.selectedAutomakerBS.asObservable();
  public selectedBattery$ = this.selectedBatteryBS.asObservable();
  public selectedCity$ = this.selectedCityBS.asObservable();
  public selectedOrder$ = this.selectedOrderBS.asObservable();
  public selectedVehicle$ = this.selectedVehicleBS.asObservable();

  /* Booleans --------------------------------------------------------------- */
  public batteryUseless$ = this.batteryUselessBS.asObservable();
  public hasValidCoupon$ = this.hasValidCouponBS.asObservable();
  public hasValidCouponApplied$ = this.hasValidCouponAppliedBS.asObservable();
  public selectedCityIsOpen$ = this.selectedCityIsOpenBS.asObservable();
  public showPhoneNumber$ = this.showPhoneNumberBS.asObservable();
  public showWhatsAppButton$ = this.showWhatsAppButtonBS.asObservable();
  public userFromFacebookShopping$ = this.userFromFacebookShoppingBS.asObservable();
  public userFromGoogleShopping$ = this.userFromGoogleShoppingBS.asObservable();
  public userFromHeavyVehicle$ = this.userFromHeavyVehicleBS.asObservable();
  public userFromPartnership$ = this.userFromPartnershipBS.asObservable();
  public wantsToReschedule$ = this.wantsToRescheduleBS.asObservable();

  /* Numbers ---------------------------------------------------------------- */
  public selectedCityLatitude$ = this.selectedCityLatitudeBS.asObservable();
  public selectedCityLongitude$ = this.selectedCityLongitudeBS.asObservable();

  /* Strings ---------------------------------------------------------------- */
  public batteryModel$ = this.batteryModelBS.asObservable();
  public leadSource$ = this.leadSourceBS.asObservable();
  public leadSourceDetails$ = this.leadSourceDetailsBS.asObservable();
  public pageTitle$ = this.pageTitleBS.asObservable();
  public paymentType$ = this.paymentTypeBS.asObservable();
  public phoneNumber$ = this.phoneNumberBS.asObservable();
  public priceTerms$ = this.priceTermsBS.asObservable();
  public userOrigin$ = this.userOriginBS.asObservable();
  public utmBlogWidgetPostSlug$: Observable<string> = this.utmBlogWidgetPostSlugBS.asObservable();
  public utmCampaign$: Observable<string> = this.utmCampaignBS.asObservable();
  public utmMedium$: Observable<string> = this.utmMediumBS.asObservable();
  public utmSource$: Observable<string> = this.utmSourceBS.asObservable();
  public utmTesteAB$: Observable<string> = this.utmTesteABBS.asObservable();
  public utmTipoSearch$: Observable<string> = this.utmTipoSearchBS.asObservable();
  public whatsAppWelcome$: Observable<string> = this.whatsAppWelcomeBS.asObservable();

  /* ------------------------------------------------------------------------ */
  /* Methods ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Arrays ----------------------------------------------------------------- */
  public updateTestimonials(value: Testimonial[]): void {
    this.testimonialsBS.next(value);
  }

  public updateTopCities(value: TopCity[]): void {
    this.topCitiesBS.next(value);
  }

  public updateTopVehicles(value: TopVehicle[]): void {
    this.topVehiclesBS.next(value);
  }

  /* Objects ---------------------------------------------------------------- */
  public updateOriginalPrices(prices: BatteryPrice): void {
    this.originalPricesBS.next(prices);
  }

  public updateDiscountedPrices(prices: BatteryPrice): void {
    this.discountedPricesBS.next(prices);
  }

  public updateInstallmentSelected(installment: any) {
    this.installmentSelectedBS.next(installment);
  }

  public updateFacebookShoppingProduct(value: FacebookShoppingProduct): void {
    this.facebookShoppingProductBS.next(value);
  }

  public updateGoogleMerchantsProduct(value: GoogleMerchantsProduct): void {
    this.googleMerchantsProductBS.next(value);
  }

  public updateSelectedAutomaker(value: Automaker): void {
    this.selectedAutomakerBS.next(value);
  }

  public updateSelectedBattery(value: Battery): void {
    this.selectedBatteryBS.next(value);
  }

  public updateSelectedCity(value: CityDetails): void {
    this.selectedCityBS.next(value);
  }

  public updateSelectedOrder(value: Order): void {
    this.selectedOrderBS.next(value);
  }

  public updateSelectedVehicle(value: SearchableVehicle): void {
    this.selectedVehicleBS.next(value);
  }

  /* Booleans --------------------------------------------------------------- */
  public updateBatteryUselessFlag(flag: boolean) {
    this.batteryUselessBS.next(flag);
  }

  public updateHasValidCouponApplied(value: boolean): void {
    this.hasValidCouponAppliedBS.next(value);
  }

  public updateHasValidCoupon(value: boolean): void {
    this.hasValidCouponBS.next(value);
  }

  public updatePriceTermsFlag(flag: boolean) {
    this.priceTermsBS.next(flag);
  }

  public updateSelectedCityIsOpen(value: boolean): void {
    this.selectedCityIsOpenBS.next(value);
  }

  public updateShowPhoneNumber(value: boolean): void {
    this.showPhoneNumberBS.next(value);
  }

  public updateShowWhatsAppButton(value: boolean): void {
    this.showWhatsAppButtonBS.next(value);
  }

  public updateUserFromFacebookShopping(value: boolean): void {
    this.userFromFacebookShoppingBS.next(value);
  }

  public updateUserFromGoogleShopping(value: boolean): void {
    this.userFromGoogleShoppingBS.next(value);
  }

  public updateUserFromHeavyVehicle(value: boolean) {
    this.userFromHeavyVehicleBS.next(value);
  }

  public updateUserFromPartnership(value: boolean): void {
    this.userFromPartnershipBS.next(value);
  }

  public updateWantsToReschedule(value: boolean): void {
    this.wantsToRescheduleBS.next(value);
  }

  /* Numbers ---------------------------------------------------------------- */
  public updateSelectedCityLatitude(value: number): void {
    this.selectedCityLatitudeBS.next(value);
  }

  public updateSelectedCityLongitude(value: number): void {
    this.selectedCityLongitudeBS.next(value);
  }

  /* Strings ---------------------------------------------------------------- */
  public updateBatteryModel(value: string): void {
    this.batteryModelBS.next(value);
  }

  public updateLeadSource(value: string): void {
    this.leadSourceBS.next(value);
  }

  public updateLeadSourceDetails(value: string): void {
    this.leadSourceDetailsBS.next(value);
  }

  public updatePageTitle(value: string): void {
    this.pageTitleBS.next(value);
  }

  public updatePaymentType(paymentType: string) {
    this.paymentTypeBS.next(paymentType);
  }

  public updatePhoneNumber(value: string): void {
    this.phoneNumberBS.next(value);
  }

  public updateUserOrigin(value: string): void {
    this.userOriginBS.next(value);
  }

  public updateUTMBlogWidgetPostSlug(value: string): void {
    this.utmBlogWidgetPostSlugBS.next(value);
  }

  public updateUTMCampaign(value: string): void {
    this.utmCampaignBS.next(value);
  }

  public updateUTMMedium(value: string): void {
    this.utmMediumBS.next(value);
  }

  public updateUTMSource(value: string): void {
    this.utmSourceBS.next(value);
  }

  public updateUTMTesteAB(value: string): void {
    this.utmTesteABBS.next(value);
  }

  public updateUTMTipoSearch(value: string): void {
    this.utmTipoSearchBS.next(value);
  }

  public updateWhatsAppWelcome(value: string): void {
    this.whatsAppWelcomeBS.next(value);
  }
}
